<template>
	<div class="projeto">
		<Nav />
		<Icon />
		<router-view/>
  	</div>
</template>

<script type="plain/text">
export default {
	components: {
  },
}
</script>